<template>
  <UiModal
    v-model:open="active"
    title="Hoe bevalt de Insert Marktplaats?"
    size="small"
    @close="closeDialog"
  >
    <p class="mb-1">
      Geef het aan met een aantal sterren
    </p>
    <div
      v-if="currentStep === 2"
      class="mb-4 flex"
    >
      <div
        v-for="index in 5"
        :key="index"
        class="pr-1.5"
      >
        <font-awesome-icon
          :icon="[starsSelected < index ? 'fal' : 'fa', 'star']"
          class="text-primary"
        />
      </div>
    </div>
    <div
      v-if="currentStep === 1"
      class=" pb-5"
    >
      <div
        class="flex"
        @mouseout="starsHovering = 0"
      >
        <div
          v-for="index in 5"
          :key="index"
          class="pr-3"
          @mouseover="starsHovering = index"
        >
          <font-awesome-icon
            :icon="[starsHovering < index ? 'fal' : 'fa', 'star']"
            size="2x"
            :class="starsHovering < index ? 'text-primary-light' : 'text-primary'"
            @click="selectStars(index)"
          />
        </div>
      </div>
    </div>
    <div v-if="currentStep === 2">
      <form
        ref="review"
        method="post"
        @submit.prevent="submitFeedback"
      >
        <div v-if="starsSelected <= 3">
          <p class="text-primary">
            Waarin kunnen we volgens u nog verbeteren?
          </p>
          <textarea
            v-model="improvement"
            class="h-56 w-full rounded-md border border-grey-medium px-6 py-4 text-grey placeholder-grey placeholder-opacity-50"
            placeholder="Beschrijf hier wat we kunnen verbeten"
            name="feedback"
          />
        </div>
        <div v-else>
          <p class="font-bold text-primary">
            Hartelijk bedankt!
          </p>
        </div>
        <button
          class="btn btn-secondary mt-5"
          type="submit"
        >
          Verzenden
        </button>
      </form>
    </div>
  </UiModal>
</template>

<script setup lang="ts">
import {DateTime} from 'luxon';

const active = ref(false);
const starsHovering = ref(0);
const starsSelected = ref(0);
const currentStep = ref(1);
const improvement = ref('');

const firstVisit = useCookie('first_visit', {
  default: () => DateTime.now().toUnixInteger(),
});
const hasReviewed = useCookie('has_reviewed', {
  default: () => false,
  expires: DateTime.now().plus({months: 1}).toJSDate(),
});

let intervalHandle: NodeJS.Timeout | undefined;

onMounted(() => {
  if (!hasReviewed.value) {
    intervalHandle = setInterval(() => {
      if (hasReviewed.value) {
        clearInterval(intervalHandle);
        intervalHandle = undefined;
        return;
      }

      const diff = DateTime.fromMillis(firstVisit.value).diffNow('minutes').minutes;

      if (diff <= -2 && !active.value && !hasReviewed.value) {
        active.value = true;
      }
    }, 1000);
  }
});

onUnmounted(() => {
  if (intervalHandle) {
    clearInterval(intervalHandle);
  }
});

function selectStars(index: number) {
  starsSelected.value = index;
  currentStep.value = 2;
}

const {mutate} = useSofieFormMutation();

async function submitFeedback() {
  try {
    await mutate({
      id: 1,
      attachments: [],
      data: {
        'aantal-sterren_1': starsSelected.value,
        verbeterpunten_2: improvement.value || '-',
      },
    });
    $toast.info('Bedankt voor de feedback!');
  } catch (e) {
    console.error(e);
    $toast.error('Er is iets mis gegaan tijdens het versturen van de beoordeling. Probeer het later nog eens.');
  } finally {
    closeDialog();
  }
}

function closeDialog() {
  hasReviewed.value = true;
  active.value = false;
}

defineExpose({
  open() {
    active.value = true;
  },
});
</script>
